import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, defaultNS, resources } from './i18Config';

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: (typeof resources)[typeof defaultLanguage];
  }
}

export const i18nInit = () => {
  i18n.use(initReactI18next).init({
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    load: 'languageOnly',
    ns: defaultNS,
    resources,
    defaultNS,
  });

  return i18n;
};
