import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/Hooks';
import { SideMenuItem } from '../../../WebComponents';
import { mobileMenuConfig, sideMenuConfig } from '../../MenuConfig';
import { MenuItem } from '../../MenuItem';
import { NavigationBarTop } from '../../components/NavigationBarTop/NavigationBarTop';
import { NavigationSideMenu } from '../../components/NavigationSideMenu/NavigationSideMenu';
import { selectMenuOpen, toggleMenu } from '../../store/NavigationBarSlice';
import './NavigationSection.scss';

export const NavigationSection: FunctionComponent = () => {
  const { instance } = useMsal();
  const { t } = useTranslation('navigation');
  const dispatch = useAppDispatch();
  const menuOpen = useAppSelector(selectMenuOpen);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const account = instance.getActiveAccount();
  const isAuthenticated = useIsAuthenticated();

  const handleNavbarToggle = (menuOpened = !menuOpen) => dispatch(toggleMenu(menuOpened));
  const handleSignOutClick = () => instance.logoutRedirect();

  const createMenuItems = (items: MenuItem[]): ReactNode =>
    items
      .filter(item => isAuthenticated || !item.authenticated)
      .map(item => (
        <SideMenuItem
          key={item.route}
          active={item.route === pathname}
          icon={item.icon}
          onClick={() => navigate(item.route)}>
          {t(item.label as unknown as TemplateStringsArray)}
        </SideMenuItem>
      ));

  const getSigningSection = (authorizedItems?: ReactNode): ReactNode => (
    <>
      {authorizedItems}
      <SideMenuItem icon="logout" onClick={handleSignOutClick}>
        {t('menu.signout', { user: account?.name })}
      </SideMenuItem>
    </>
  );

  const getSideMenuItems = (): ReactNode => (
    <>
      <SideMenuItem icon="home" active={pathname === '/'} onClick={() => navigate('/')}>
        {t('menu.home')}
      </SideMenuItem>

      {createMenuItems(sideMenuConfig)}
    </>
  );

  const getMobileItems = (): ReactNode => (
    <>
      {createMenuItems(mobileMenuConfig)}
      {getSigningSection()}
    </>
  );

  return (
    <>
      <NavigationSideMenu
        menuOpen={menuOpen}
        sideMenuItems={getSideMenuItems()}
        mobileItems={getMobileItems()}
        handleNavbarToggle={handleNavbarToggle}
      />

      <NavigationBarTop
        title={t('menu.title')}
        isAuthenticated={isAuthenticated}
        handleSignOut={handleSignOutClick}
        handleNavbarToggle={handleNavbarToggle}
      />
    </>
  );
};
