import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../shared/forms/components/SearchInput/SearchInput';
import { Part } from '../../Types';
import './PartsSearch.scss';
import { Button } from '../../../../core/WebComponents';

export interface PartsSearchProps {
  id: string;
  options: Part[];
  placeholder: string;
  minLength: number;
  isLoading: boolean;
  searchText: string;
  onSelect: (selected?: Part) => void;
  onSearch: (query: string) => void;
}

export const PartsSearch: FunctionComponent<PartsSearchProps> = (props: PartsSearchProps) => {
  const [selected, setSelected] = useState<Part | undefined>();
  const { t } = useTranslation('parts');

  const { control } = useForm({
    defaultValues: {
      searchParts: '',
    },
  });

  const onSearch = (term: string) => props.onSearch(term);
  const onSelect = (newSelected: Part) => {
    setSelected(newSelected);
    props.onSelect(newSelected);
  };
  const onSearchButton = (): void => props.onSelect(selected);

  return (
    <div className="parts-search">
      <div className="search-label-container">
        <label data-cy="search-label" htmlFor="search">
          {t('search.label')}
        </label>
      </div>
      <div className="search-box-container">
        <SearchInput
          control={control}
          name="searchParts"
          onChange={onSearch}
          onSelect={onSelect}
          options={props.options}
          converter={x => x.name}
        />
        <Button variant="primary" onClick={onSearchButton} data-cy="search-button">
          {t('search.button')}
        </Button>
      </div>
    </div>
  );
};
