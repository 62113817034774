import { GridExpandChangeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPaginationParams } from '../../../../core/Http/HttpService';
import { QueryParams } from '../../../../core/Http/QueryParams';
import { useAppDispatch, useAppSelector } from '../../../../store/Hooks';
import { Part } from '../../Types';
import { PartsGrid } from '../../components/PartsGrid/PartsGrid';
import { PartsSearch } from '../../components/PartsSearch/PartsSearch';
import { useLazyGetPartsQuery, useLazyGetPartsTypeaheadQuery } from '../../store/PartsApi';
import { selectSortDescriptors, sortParts } from '../../store/PartsSlice';
import { getProperlyExpandedItemsList } from '../../../../shared/grid/Methods';

export const PartsPage: FunctionComponent = () => {
  const { t } = useTranslation('parts');
  const dispatch = useAppDispatch();
  const sortDescriptors = useAppSelector(selectSortDescriptors);
  const [getParts, partsQuery] = useLazyGetPartsQuery();
  const [getPartsTypeahead, typeaheadSuggestions] = useLazyGetPartsTypeaheadQuery();
  const [parts, setParts] = useState(partsQuery.data);

  useEffect(() => {
    setParts(partsQuery.data);
  }, [partsQuery.data]);

  const handleTypeaheadSearch = (term: string) => {
    getPartsTypeahead(term, true);
  };

  const handleTypeaheadSelect = (selected?: Part): void => {
    let parameters: QueryParams = {
      ...parts?.links.self,
      ...parts?.pagination,
      page: 1,
      pageSize: 10,
    };
    if (selected) {
      parameters = {
        ...parameters,
        params: { name: selected.name },
      };
    }
    getParts(parameters, true);
  };

  const handlePageChange = (event: GridPageChangeEvent) => {
    if (parts !== undefined) {
      const params = createPaginationParams(parts, event.page.skip, event.page.take);
      getParts(params, true);
    }
  };

  const onSortChange = (event: GridSortChangeEvent) => {
    dispatch(sortParts(event.sort));
    const sort: string | undefined = event.sort
      .filter(sortDescriptor => sortDescriptor.dir)
      .map(sortDescriptor => `${sortDescriptor.field}-${sortDescriptor.dir}`)
      .shift();

    getParts({
      ...parts?.links.self,
      ...parts?.pagination,
      sort,
    });
  };

  const handleRowExpand = (event: GridExpandChangeEvent) => {
    setParts(
      oldData =>
        oldData && {
          ...oldData,
          items: getProperlyExpandedItemsList<Part>(oldData.items, event.dataItem.id),
        },
    );
  };

  return (
    <div className="page-container">
      <PartsSearch
        id={'parts-typeahead'}
        options={typeaheadSuggestions.data?.items || []}
        placeholder={t('search.placeholder')}
        minLength={3}
        isLoading={typeaheadSuggestions.isLoading}
        onSearch={handleTypeaheadSearch}
        onSelect={handleTypeaheadSelect}
        searchText={'Searching for available items...'}
      />
      {partsQuery.isLoading && <span>Loading grid...</span>}
      {parts && (
        <PartsGrid
          parts={parts}
          sortDescriptors={sortDescriptors}
          onPageChange={handlePageChange}
          onSortChange={onSortChange}
          onRowExpand={handleRowExpand}
        />
      )}
    </div>
  );
};
