import { GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { SyntheticEvent } from 'react';
import { Part } from '../../features/Parts/Types';
import { MobileGridCellProps } from './Types';

export const getProperlyExpandedItemsList = <T extends Part>(items: T[], id: string | number) =>
  items.map(item => (item.id === id ? { ...item, expanded: !item.expanded } : item));

export const handleExpandClick = ({ dataItem, onExpand }: MobileGridCellProps) => {
  const event: GridExpandChangeEvent = {
    dataItem,
    value: dataItem.expanded as boolean,
    target: {},
    nativeEvent: {},
    syntheticEvent: {} as SyntheticEvent,
  } as GridExpandChangeEvent;
  onExpand(event);
};
