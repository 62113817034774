import { FC, useState } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../../../../core/WebComponents';
import './SearchInput.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type SearchInputProps = UseControllerProps<any> & {
  options: any[];
  converter: (param: any) => string;
  label?: string;
  onChange?: (event: any) => void;
  onSelect?: (event: any) => void;
};

export const SearchInput: FC<SearchInputProps> = props => {
  const [isFocused, setIsFocused] = useState(false);
  const { field, fieldState } = useController(props);
  const { t } = useTranslation('profile');
  const CLOSE_DELAY = 200;

  const handleTypeaheadInput = (e: Event) => {
    const { value } = (e as CustomEvent).detail;
    field.onChange(value);
    props.onChange && props.onChange(value);
  };

  const handleSelectValue = (value: any) => {
    field.onChange(props.converter(value));
    props.onSelect && props.onSelect(value);
    setIsFocused(false);
  };

  const handleTypeaheadBlur = () => {
    if (!props.options.map(props.converter).includes(String(field.value))) {
      field.onChange('');
    }

    field.onBlur();
    setTimeout(() => setIsFocused(false), CLOSE_DELAY);
  };

  return (
    <div className="search-input-row">
      <SearchField
        name="search-input"
        label={props.label}
        value={field.value}
        onInput={handleTypeaheadInput}
        onFocus={() => setIsFocused(true)}
        onBlur={handleTypeaheadBlur}
        data-cy="search-input"
      />

      <div className="row-extra-content">
        {fieldState.invalid && (
          <div className="error-message" data-cy="error-message">
            {t(fieldState.error?.message as unknown as TemplateStringsArray)}
          </div>
        )}
        {isFocused && (
          <div data-cy="suggestions-list" className="suggestions-container">
            {props.options
              .filter((suggestion: any) =>
                props.converter(suggestion).toLowerCase().includes(String(field.value).trim().toLowerCase()),
              )
              .map((suggestion: any, index: number) => {
                const suggestionValue = props.converter(suggestion);
                return (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    key={`${suggestionValue}-${index}`}
                    className="suggestions-item"
                    onClick={() => handleSelectValue(suggestion)}>
                    {suggestionValue}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
/* eslint-enable @typescript-eslint/no-explicit-any */
