import { SortDescriptor } from '@progress/kendo-data-query';
import {
  Grid,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridPageChangeEvent,
  GridProps,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { ComponentType, FC, ReactNode } from 'react';
import { AnyResponseType } from '../../../core/Http/RestResponse';
import './DataGrid.scss';

interface DataGridProps {
  dataResponse: AnyResponseType;
  sortDescriptors?: SortDescriptor[];
  onPageChange: (event: GridPageChangeEvent) => void;
  onSortChange?: (event: GridSortChangeEvent) => void;
  children?: ReactNode;
  mobileColumn?: JSX.Element;
  onRowExpand?: (event: GridExpandChangeEvent) => void;
  getDetailComponent?: ComponentType<GridDetailRowProps>;
}

export const DataGrid: FC<DataGridProps> = ({
  children,
  dataResponse,
  sortDescriptors,
  mobileColumn,
  onPageChange,
  onSortChange,
  onRowExpand,
  getDetailComponent,
}) => {
  const gridParams: GridProps = {
    data: { ...dataResponse, data: dataResponse.items },
    pageable: {
      buttonCount: 5,
      // eslint-disable-next-line no-magic-numbers
      pageSizes: [10, 25, 50],
    },
    skip: (dataResponse.pagination.page - 1) * dataResponse.pagination.pageSize,
    take: dataResponse.pagination.pageSize,
    total: dataResponse.pagination.pageSize * dataResponse.pagination.numberOfPages,
    onPageChange,
    className: 'row',
    scrollable: 'none',
    sortable: {
      allowUnsort: true,
      mode: 'single',
    },
    sort: sortDescriptors,
    onSortChange,
  };

  const desktopGrid = <Grid {...gridParams}>{children}</Grid>;
  const mobileGrid = (
    <Grid {...gridParams} expandField={'expanded'} onExpandChange={onRowExpand} detail={getDetailComponent}>
      {mobileColumn}
    </Grid>
  );

  return (
    <>
      <div className="desktop-grid" data-cy="desktop-grid">
        {desktopGrid}
      </div>
      <div className="mobile-grid" data-cy="mobile-grid">
        {mobileGrid}
      </div>
    </>
  );
};
