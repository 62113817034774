import { configureStore } from '@reduxjs/toolkit';
import navigationBarReducer from '../core/Layout/store/NavigationBarSlice';
import notificationsReducer from '../core/Notifications/store/NotificationsSlice';
import securityReducer from '../core/Security/store/SecuritySlice';
import partsReducer from '../features/Parts/store/PartsSlice';
import { apiSlice } from './Api';

export const store = configureStore({
  reducer: {
    security: securityReducer,
    navigationBar: navigationBarReducer,
    parts: partsReducer,
    notifications: notificationsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
});
