import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/Types';

interface NotificationsState {
  notifications: { id: number; text: string }[];
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
};

const SLICE_NAME = 'notifications';

export const notificationsSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialNotificationsState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<string>) => {
      const firstNotificationId = -1;
      const lastId = state.notifications[state.notifications.length - 1]?.id || firstNotificationId;
      state.notifications.push({ id: lastId + 1, text: payload });
    },
    removeNotification: (state, { payload }: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(notification => notification.id !== payload);
    },
  },
});

export const { addNotification, removeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectNotifications = (state: RootState) => state.notifications.notifications;
