import { createHttpParams } from '../../../core/Http/HttpService';
import { QueryParams } from '../../../core/Http/QueryParams';
import { apiSlice, apiParams } from '../../../store/Api';
import { Part } from '../Types';
import { Response } from '../../../core/Http/RestResponse';

export const partsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getParts: builder.query<Response<Part[]>, QueryParams>({
      query: params => ({
        url: apiParams.paths.parts,
        params: createHttpParams(params),
      }),
      transformResponse: (response: Response<Part[]>) => ({
        ...response,
        items: [...response.items.map(item => ({ ...item, expanded: false }))],
      }),
    }),
    getPartsTypeahead: builder.query<Response<Part[]>, string>({
      query: term => ({
        url: apiParams.paths.parts,
        params: createHttpParams({
          unique: 'name',
          sort: 'name',
          pageSize: 5,
          params: {
            name: term,
          },
        }),
      }),
    }),
  }),
});

export const { useLazyGetPartsQuery, useLazyGetPartsTypeaheadQuery } = partsApiSlice;
