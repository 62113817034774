import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/Types';

interface SecurityState {
  accessToken: string | null;
}

export const initialSecurityState: SecurityState = {
  accessToken: null,
};

const SLICE_NAME = 'security';

export const securitySlice = createSlice({
  name: SLICE_NAME,
  initialState: initialSecurityState,
  reducers: {
    setAccessToken: (state, { payload: accessToken }: PayloadAction<SecurityState['accessToken']>) => {
      state.accessToken = accessToken;
    },
  },
});

export const { setAccessToken } = securitySlice.actions;

export default securitySlice.reducer;

export const selectAccessToken = (state: RootState) => state.security.accessToken;
