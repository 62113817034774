import i18next from 'i18next';
import React, { FunctionComponent } from 'react';
import './ChangeLanguage.scss';
import { TranslationLanguage, languages as languagesList } from '../../../Translation/translationConfig';
import { Dropdown, DropdownOption } from '../../../WebComponents';
import { useTranslation } from 'react-i18next';

export const ChangeLanguage: FunctionComponent = () => {
  const languages: TranslationLanguage[] = [...languagesList];
  const currentLanguage = i18next.resolvedLanguage;
  const { t } = useTranslation('navigation');

  const onSelectLanguage = (language: string) => {
    if (language !== currentLanguage) {
      i18next.changeLanguage(language);
    }
  };

  return (
    <Dropdown
      className={'change-language-dropdown'}
      size="small"
      floatingLabel={false}
      value={currentLanguage}
      onChange={(e: Event) => onSelectLanguage((e as CustomEvent).detail.value)}>
      {languages.map(language => (
        <DropdownOption key={language.key} value={language.key}>
          {t(language.label as unknown as TemplateStringsArray)}
        </DropdownOption>
      ))}
    </Dropdown>
  );
};
