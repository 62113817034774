import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PageNotFound } from './features/ErrorPages/PageNotFound';
import { PartsPage } from './features/Parts/containers/PartsPage/PartsPage';

const Profile = React.lazy(() =>
  import('./features/Profile/containers/ProfilePage/ProfilePage').then(module => ({ default: module.ProfilePage })),
);

/* eslint-disable react/no-multi-comp */
const SuspenseWrapper: FunctionComponent<{ component: ReactNode }> = ({ component }) => (
  <React.Suspense fallback={<>...</>}>{component}</React.Suspense>
);

export const AppRouting: FunctionComponent = (): ReactElement => {
  const routes = useRoutes([
    {
      path: '/',
      element: <PartsPage />,
    },
    {
      path: '/parts',
      element: <Navigate to="/" replace={true} />,
    },
    {
      path: '/profile',
      element: <SuspenseWrapper component={<Profile />} />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ]);

  return <main className="main-section">{routes}</main>;
};
