import React, { FunctionComponent, ReactNode } from 'react';
import './NavigationSideMenu.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Logotype, SideMenu, SideMenuGroup, SideMenuTopBar } from '../../../WebComponents';
import { ChangeLanguage } from '../ChangeLanguage/ChangeLanguage';

interface NavigationSideMenuProps {
  menuOpen: boolean;
  sideMenuItems: ReactNode;
  mobileItems: ReactNode;
  handleNavbarToggle: (menuOpened?: boolean) => void;
}

export const NavigationSideMenu: FunctionComponent<NavigationSideMenuProps> = ({
  menuOpen,
  handleNavbarToggle,
  sideMenuItems,
  mobileItems,
}) => {
  const { t } = useTranslation('navigation');

  return (
    <SideMenu open={menuOpen} className="side-menu" onToggle={() => handleNavbarToggle()} data-cy="side-menu">
      <SideMenuTopBar>
        <Link to="/">
          <Logotype variant="volvo-spread-word-mark" height={24} width={132} role="button" />
        </Link>
      </SideMenuTopBar>

      {sideMenuItems}

      <SideMenuGroup className="mobile-menu-items" text={t('menu.user') as string}>
        {mobileItems}
        <ChangeLanguage />
      </SideMenuGroup>
    </SideMenu>
  );
};
