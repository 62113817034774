import React from 'react';
import { createRoot } from 'react-dom/client';
import { i18nInit } from './i18n';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './store/Store';
import { MsalProvider } from '@azure/msal-react';
import { appSecurityConfig } from './core/Security/authConfig';

i18nInit();

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={'loading'}>
      <Provider store={store}>
        <MsalProvider instance={appSecurityConfig}>
          <App />
        </MsalProvider>
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
);
