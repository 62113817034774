import { FunctionComponent } from 'react';
import { AppBarTop, AppBarTopItem } from '../../../WebComponents';
import { ChangeLanguage } from '../ChangeLanguage/ChangeLanguage';
import './NavigationBarTop.scss';

export interface NavigationBarTopProps {
  title: string;
  isAuthenticated: boolean;
  handleSignOut: () => void;
  handleNavbarToggle: (menuOpened?: boolean) => void;
}

export const NavigationBarTop: FunctionComponent<NavigationBarTopProps> = ({
  title,
  handleSignOut,
  handleNavbarToggle,
  isAuthenticated,
}) => {
  const mobileNavBar = (
    <div className="mobile-header">
      <AppBarTop className="volvo-app-bar-top" serviceName={title}>
        <AppBarTopItem
          className="menu-hamburger"
          icon="hamburger-menu"
          onClick={() => handleNavbarToggle(true)}
          slot="end"
        />
      </AppBarTop>
    </div>
  );

  const destkopNavBar = (
    <div className="desktop-header">
      <AppBarTop className="volvo-app-bar-top" serviceName={title}>
        {isAuthenticated ? (
          <AppBarTopItem className="user-section" icon="logout" slot="end" onClick={() => handleSignOut()} />
        ) : (
          <AppBarTopItem className="user-section" icon="login" slot="end" />
        )}
      </AppBarTop>
      <ChangeLanguage />
    </div>
  );

  return (
    <>
      {mobileNavBar}
      {destkopNavBar}
    </>
  );
};
