import footerEn from './locales/en/footer.json';
import footerPl from './locales/pl/footer.json';
import shoppingCartEn from './locales/en/shopping-cart.json';
import shoppingCartPl from './locales/pl/shopping-cart.json';
import profileEn from './locales/en/profile.json';
import profilePl from './locales/pl/profile.json';
import errorPagesEn from './locales/en/error-pages.json';
import errorPagesPl from './locales/pl/error-pages.json';
import homeEn from './locales/en/home.json';
import homePl from './locales/pl/home.json';
import navigationEn from './locales/en/navigation.json';
import navigationPl from './locales/pl/navigation.json';
import ordersEn from './locales/en/orders.json';
import ordersPl from './locales/pl/orders.json';
import partsEn from './locales/en/parts.json';
import partsPl from './locales/pl/parts.json';
import securityEn from './locales/en/security.json';
import securityPl from './locales/pl/security.json';
import checkoutEn from './locales/en/checkout.json';
import checkoutPl from './locales/pl/checkout.json';

export const defaultNS = 'footer';
export const defaultLanguage = 'en';

export const resources = {
  en: {
    errorPages: errorPagesEn,
    footer: footerEn,
    profile: profileEn,
    shoppingCart: shoppingCartEn,
    home: homeEn,
    navigation: navigationEn,
    orders: ordersEn,
    parts: partsEn,
    security: securityEn,
    checkout: checkoutEn,
  },
  pl: {
    errorPages: errorPagesPl,
    footer: footerPl,
    profile: profilePl,
    shoppingCart: shoppingCartPl,
    home: homePl,
    navigation: navigationPl,
    orders: ordersPl,
    parts: partsPl,
    security: securityPl,
    checkout: checkoutPl,
  },
} as const;
