import { QueryParams } from './QueryParams';
import { AnyResponseType } from './RestResponse';


export const createPaginationParams = (response: AnyResponseType, skip: number, take: number): QueryParams => ({
  ...response.links.self,
  ...response.pagination,
  page: skip / response.pagination.pageSize + 1,
  pageSize: take,
});

export const createHttpParams = (parameters: QueryParams) => {
  const { params, ...otherParams } = parameters;

  const httpParams: QueryParams = { ...otherParams };

  Object.entries(params || {}).forEach(([key, val]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (httpParams as any)[`params[${key}]`] = val;
  });

  return httpParams;
};
