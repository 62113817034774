import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import { IconButton, Snackbar } from '../../WebComponents';
import { removeNotification, selectNotifications } from '../store/NotificationsSlice';

export const NotificationsContainer: FunctionComponent = () => {
  const notifications = useAppSelector(selectNotifications);
  const isSnackbarOpen = notifications.length > 0;
  const dispatch = useAppDispatch();

  return (
    <Snackbar data-cy="notifications-container" open={isSnackbarOpen}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
        <IconButton icon="close" onClick={() => dispatch(removeNotification(notifications[0]?.id))} />
      </div>

      <div style={{ paddingBottom: '20px', fontSize: '20px' }}>{notifications[0]?.text}</div>
    </Snackbar>
  );
};
