import { Environment } from './Environment';

declare global {
  // eslint-disable-next-line no-var
  var __env: Environment;
}

export function environment(): Environment {
  return (
    globalThis.__env || {
      host: '',
      api: '',
      url: '',
      clientId: '',
      scopes: [],
      environmentName: '',
      loaded: false,
      production: false,
    }
  );
}

export function getBaseName(url = environment().host): string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_httpPart, _emptyPart, _domainName, ...rest] = url.split('/');

  return `/${rest.join('/')}`;
}
