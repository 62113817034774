import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { environment } from '../environment/EnvironmentStatic';
import { RootState } from './Types';

export const apiParams = {
  paths: {
    parts: '/parts',
    orderLines: '/order-lines',
    orders: '/orders',
    users: '/users',
  },
  methods: {
    POST: 'POST',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
  },
  tags: {
    parts: 'Parts',
    orderLines: 'Order lines',
    shoppingCart: 'Shopping cart',
    orders: 'Orders',
    orderDetails: 'Order details',
  },
};

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(apiParams.tags),
  baseQuery: fetchBaseQuery({
    baseUrl: environment().api,
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).security.accessToken;

      if (jwtToken) {
        headers.set('Authorization', `Bearer ${jwtToken}`);
      } else {
        alert('Auhotirization header error(token)');
      }

      return headers;
    },
  }),

  endpoints: () => ({}),
});
