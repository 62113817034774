import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/Hooks';
import { addNotification } from '../../Notifications/store/NotificationsSlice';
import { loginRequest } from '../authConfig';
import { setAccessToken } from '../store/SecuritySlice';

export const useAuthCallbackHandler = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('security');
  const { instance, accounts, inProgress } = useMsal();
  const [isShowed, setIsShowed] = useState(false);

  instance.initialize();

  useEffect(() => {
    function successCallback(token: string, user: string | undefined): void {
      dispatch(setAccessToken(token));
      dispatch(addNotification(t('security.user-logged-in-message', { user })));
    }

    async function handleAuth(): Promise<void> {
      const response = await instance.handleRedirectPromise();
      const account = response ? response.account : instance.getAllAccounts()[0];
      if (account && response) {
        dispatch(setAccessToken(response.accessToken));
        successCallback(response.accessToken, response.account?.name);
      } else if (account) {
        try {
          const silentResponse = await instance.acquireTokenSilent(loginRequest);
          successCallback(silentResponse.accessToken, silentResponse.account?.name);
        } catch (e) {
          await instance.acquireTokenRedirect(loginRequest);
        }
      } else {
        await instance.loginRedirect(loginRequest);
      }
      setIsShowed(true);
    }

    if (isShowed === false) {
      handleAuth();
    }
  }, [inProgress, accounts, instance, isShowed, dispatch, t]);
};
