import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/Types';

const SLICE_NAME = 'navigation';

interface NavigationBarState {
  menuOpen: boolean;
}

export const initialNavigationBarState: NavigationBarState = {
  menuOpen: false,
};

const navigationBarSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialNavigationBarState,
  reducers: {
    toggleMenu: (state, { payload: menuOpened }: PayloadAction<boolean>) => {
      state.menuOpen = menuOpened;
    },
  },
});

export const { toggleMenu } = navigationBarSlice.actions;
export const selectMenuOpen = (state: RootState) => state.navigationBar.menuOpen;

export default navigationBarSlice.reducer;
