import { GridDetailRowProps } from '@progress/kendo-react-grid';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Part } from '../../Types';

export const MobilePartsGridDetail: FunctionComponent<GridDetailRowProps> = ({ dataItem }) => {
  const { partAvailability = { balance: 0 } } = dataItem as Part;

  const { t } = useTranslation('parts');

  return (
    <section className="mobile-item-content">
      <p data-cy="id">
        <strong>{t('grid.id')}</strong> {dataItem.id}
      </p>
      <p data-cy="serial-number">
        <strong>{t('grid.serial-no')}</strong> {dataItem.serialNumber}
      </p>
      <p data-cy="unit">
        <strong>{t('grid.unit')}</strong> {dataItem.unit}
      </p>
      <p data-cy="balance">
        <strong>{t('grid.availability')}</strong> {partAvailability.balance}
      </p>
      <p data-cy="price">
        <strong>{t('grid.price')}</strong> {dataItem.price.amount} {dataItem.price.currency}
      </p>
    </section>
  );
};
