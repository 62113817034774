import { SortDescriptor } from '@progress/kendo-data-query';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/Types';

const SLICE_NAME = 'parts';

export interface PartsState {
  sortDescriptors: SortDescriptor[];
}

export const initialPartsState: PartsState = {
  sortDescriptors: [],
};

const partsSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialPartsState,
  reducers: {
    sortParts: (state, { payload: sortDescriptors }: PayloadAction<SortDescriptor[]>) => {
      state.sortDescriptors = sortDescriptors;
    },
  },
});

export const selectSortDescriptors = (state: RootState) => state.parts.sortDescriptors;

export const { sortParts } = partsSlice.actions;

export default partsSlice.reducer;
