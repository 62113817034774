import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterBar } from '../../WebComponents';

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation('footer');
  const date = new Date();
  const year = date.getFullYear().toString();
  const copyright = t('copyright', { year });

  return <FooterBar copyright={copyright} data-cy={'footer'} />;
};
