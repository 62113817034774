import { MenuItem } from './MenuItem';

export const sideMenuConfig: MenuItem[] = [
  {
    label: 'menu.profile',
    route: '/profile',
    authenticated: true,
    icon: 'user',
  },
];

export const mobileMenuConfig: MenuItem[] = [];
