import { PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { environment } from '../../environment/EnvironmentStatic';

export const appSecurityConfig = new PublicClientApplication({
  auth: {
    clientId: environment().clientId,
    authority: environment().url,
    redirectUri: environment().host,
    postLogoutRedirectUri: environment().host,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
});

export const loginRequest: RedirectRequest = {
  scopes: environment().scopes,
};
