import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { LayoutSection } from './core/Layout';
import { NotificationsContainer } from './core/Notifications/components/NotificationsContainer';
import { useAuthCallbackHandler } from './core/Security';
import { getBaseName } from './environment/EnvironmentStatic';

export const App: FunctionComponent = () => {
  useAuthCallbackHandler();

  return (
    <BrowserRouter basename={getBaseName()}>
      <LayoutSection />
      <NotificationsContainer />
    </BrowserRouter>
  );
};
