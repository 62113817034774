import React, { FunctionComponent } from 'react';
import { MobileGridCellProps } from '../../../../shared/grid/Types';
import { handleExpandClick } from '../../../../shared/grid/Methods';
import './MobilePartsCell.scss';

export const MobilePartsCell: FunctionComponent<MobileGridCellProps> = ({ dataItem, onExpand }) => (
  <td colSpan={1} onClick={() => handleExpandClick({ dataItem, onExpand })} data-cy="mobile-parts-cell">
    <p className="k-mobile-title mobile-item-title">{dataItem.name}</p>
  </td>
);
