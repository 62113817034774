import { createComponent } from '@lit-labs/react';
import {
  AppBarTopItem as AppBarTopItemWebComponent,
  AppBarTop as AppBarTopWebComponent,
  Button as ButtonWebComponent,
  DropdownOption as DropdownOptionWebComponent,
  Dropdown as DropdownWebComponent,
  FooterBar as FooterBarWebComponent,
  IconButton as IconButtonWebComponent,
  Logotype as LogotypeWebComponent,
  NumberInput as NumberInputWebComponent,
  SearchField as SearchFieldWebComponent,
  SideMenuGroup as SideMenuGroupWebComponent,
  SideMenuItem as SideMenuItemWebComponent,
  SideMenuTopBar as SideMenuTopBarWebComponent,
  SideMenu as SideMenuWebComponent,
  Snackbar as SnackbarWebComponent,
  SystemIcon as SystemIconWebComponent,
  TextField as TextFieldWebComponent,
} from '@volvo/vcdk';
import React from 'react';

export const Logotype = createComponent({
  tagName: 'volvo-logotype',
  elementClass: LogotypeWebComponent,
  react: React,
});

export const AppBarTop = createComponent({
  tagName: 'volvo-app-bar-top',
  elementClass: AppBarTopWebComponent,
  react: React,
});

export const AppBarTopItem = createComponent({
  tagName: 'volvo-app-bar-top-item',
  elementClass: AppBarTopItemWebComponent,
  events: { onClick: 'click' },
  react: React,
});

export const SideMenu = createComponent({
  tagName: 'volvo-side-menu',
  elementClass: SideMenuWebComponent,
  events: { onToggle: 'toggle' },
  react: React,
});

export const SideMenuItem = createComponent({
  tagName: 'volvo-side-menu-item',
  elementClass: SideMenuItemWebComponent,
  events: { onClick: 'click' },
  react: React,
});
export const SideMenuTopBar = createComponent({
  tagName: 'volvo-side-menu-top-bar',
  elementClass: SideMenuTopBarWebComponent,
  react: React,
});

export const SideMenuGroup = createComponent({
  tagName: 'volvo-side-menu-group',
  elementClass: SideMenuGroupWebComponent,
  react: React,
});

export const Dropdown = createComponent({
  tagName: 'volvo-dropdown',
  elementClass: DropdownWebComponent,
  events: { onChange: 'change' },
  react: React,
});

export const DropdownOption = createComponent({
  tagName: 'volvo-dropdown-option',
  elementClass: DropdownOptionWebComponent,
  events: { onClick: 'click' },
  react: React,
});

export const FooterBar = createComponent({
  tagName: 'volvo-footer-bar',
  elementClass: FooterBarWebComponent,
  react: React,
});

export const TextField = createComponent({
  tagName: 'volvo-text-field',
  elementClass: TextFieldWebComponent,
  react: React,
  events: {
    onInput: 'input',
    onBlur: 'blur',
  },
});

export const NumberInput = createComponent({
  tagName: 'volvo-number-input',
  elementClass: NumberInputWebComponent,
  react: React,
  events: {
    onInput: 'input',
    onIncrease: 'increase',
    onDecrease: 'decrease',
  },
});

export const SearchField = createComponent({
  tagName: 'volvo-search-field',
  elementClass: SearchFieldWebComponent,
  react: React,
  events: {
    onInput: 'input',
    onBlur: 'blur',
  },
});

export const SystemIcon = createComponent({
  tagName: 'volvo-system-icon',
  elementClass: SystemIconWebComponent,
  react: React,
});

export const Button = createComponent({
  tagName: 'volvo-button',
  elementClass: ButtonWebComponent,
  react: React,
  events: { onClick: 'click' },
});

export const Snackbar = createComponent({
  tagName: 'volvo-snackbar',
  elementClass: SnackbarWebComponent,
  react: React,
});

export const IconButton = createComponent({
  tagName: 'volvo-icon-button',
  elementClass: IconButtonWebComponent,
  react: React,
});
