import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemIcon } from '../../core/WebComponents';

export const PageNotFound: FunctionComponent = () => {
  const { t } = useTranslation('errorPages');

  return (
    <div className="error-page-container">
      <div className="icon-container" data-cy="icon">
        <SystemIcon size={72} icon="warning" filled={true} />
      </div>
      <h2 data-cy="text">{t('404')}</h2>
    </div>
  );
};
