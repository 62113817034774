import React, { FunctionComponent } from 'react';
import { AppRouting } from '../../../../AppRouting';
import { Footer } from '../../../Footer';
import { NavigationSection } from '../..';
import './LayoutSection.scss';

export const LayoutSection: FunctionComponent = () => {
  return (
    <div className="layout-section">
      <NavigationSection />
      <AppRouting />
      <Footer />
    </div>
  );
};
