import { SortDescriptor } from '@progress/kendo-data-query';
import {
  GridCellProps,
  GridColumn,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Response } from '../../../../core/Http/RestResponse';
import { DataGrid } from '../../../../shared/grid/containers/DataGrid';
import { Part } from '../../Types';
import { MobilePartsCell } from '../MobilePartsCell/MobilePartsCell';
import { MobilePartsGridDetail } from '../MobilePartsGridDetail/MobilePartsGridDetail';

export interface PartsGridProps {
  parts: Response<Part[]>;
  sortDescriptors: SortDescriptor[];
  onPageChange: (event: GridPageChangeEvent) => void;
  onSortChange: (event: GridSortChangeEvent) => void;
  onRowExpand: (event: GridExpandChangeEvent) => void;
}

export const PartsGrid: FunctionComponent<PartsGridProps> = props => {
  const { t } = useTranslation('parts');
  const { parts, onPageChange, onSortChange } = props;

  const getDetailComponent = (detailProps: GridDetailRowProps) => <MobilePartsGridDetail {...detailProps} />;

  const mobileColumn = (
    <GridColumn
      field={'name'}
      title={t('grid.name')}
      cell={(mobileCellProps: GridCellProps) => <MobilePartsCell {...mobileCellProps} onExpand={props.onRowExpand} />}
    />
  );

  return (
    <DataGrid
      dataResponse={parts}
      onPageChange={onPageChange}
      sortDescriptors={props.sortDescriptors}
      onSortChange={onSortChange}
      mobileColumn={mobileColumn}
      onRowExpand={props.onRowExpand}
      getDetailComponent={getDetailComponent}>
      <GridColumn field={'id'} title={t('grid.id')} />
      <GridColumn field={'name'} title={t('grid.name')} />
      <GridColumn field={'serialNumber'} title={t('grid.serial-no')} />
      <GridColumn field={'unit'} title={t('grid.unit')} />
      <GridColumn field={'partAvailability.balance'} title={t('grid.availability')} />
      <GridColumn
        field={'price.amount'}
        title={t('grid.price')}
        cell={(cellProps: GridCellProps) => (
          <td>
            {cellProps.dataItem.price.amount} {cellProps.dataItem.price.currency}
          </td>
        )}
      />
    </DataGrid>
  );
};
